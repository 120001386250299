import { css } from "@emotion/react";
import { Table, useMantineTheme } from "@mantine/core"
import * as React from "react"
import { useState } from "react";
import Layout from "../components/layout";

// static data
const schools = [
  { id: 0, name: "Lakeview Middle", street: "123 Dune Lake Dr.", city: "Lexington", state: "South Carolina", country: "USA" },
  { id: 1, name: "Lakeview High", street: "456 Lake Lake Dr.", city: "Charlotte", state: "North Carolina", country: "USA" },
  { id: 2, name: "Applewood Middle", street: "123 Apple Caramel Dr.", city: "Austin", state: "Texas", country: "USA" },
  { id: 3, name: "San Diego Middle", street: "123 Sand Pot Dr.", city: "Seattle", state: "Washington", country: "USA" }
];

export default function SchoolsPage(props) {
  // static mapped school data
  const schoolRows = schools.map((school) => (
    <tr key={school.id}>
      <td>{school.name}</td>
      <td>{school.street}</td>
      <td>{school.city}</td>
      <td>{school.state}</td>
      <td>{school.country}</td>
    </tr>
  ));

  // Table thread style
  const threadStyle = css({
    display: "table-header-group"
  });

  return (
    <Layout>
      <Table striped highlightOnHover withColumnBorders>
        <thread css={threadStyle}>
          <tr>
            <th>Name</th>
            <th>Street</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th></th>
          </tr>
        </thread>
        <tbody>{schoolRows}</tbody>
      </Table>
    </Layout>
  );
}